<template>
  <div class="home">
    <!-- Special Note-->
    <div class="note-info" hidden>
      <div class="info notes">
        <p><strong>Info!</strong> <a
            href="https://www.docdroid.net/9tzskI6/qigong-kurse-docx"
            target="_blank"
        >Qigong-Kurse</a
        > ab <span>26.06.2023</span> neu buchbar-anmeldung unter E-mail</p>
      </div>
    </div>
    <!--Start OverView-->
    <div id="overview" hidden class="overview text-centir">
      <h2>Einleitung</h2>
      <p class="line"></p>
      <div class="container overview-text">
        <p class="overview-text-content">
          Willkommen in meiner Praxis für komplementäre-physiotherapeutische und
          traditionelle chinesische Medizin. Ich freu mich über Ihren Besuch
          und Ihr Interesse an alternativen Therapien. Nachfolgend habe ich
          Ihnen alle Informationen zusammengestellt, die sie brauchen, um sich
          vertrauensvoll mit Ihren Beschwerden an mich zu wenden. Im Mittelpunkt
          meiner Arbeit, stehen nicht nur Ihre körperlichen Beschwerden, sondern
          auch Ihre körperliche Konstitution und Ihre individuelle
          Lebenssituation. So können wir gemeinsam ein Konzept für Ihre
          Gesundheit und Ihr Wohlbefinden erarbeiten.
        </p>
        <a
          class="about-praxis"
          href="https://pdfhost.io/v/JFyZQJ9yo_Einleitung_PDF"
          target="_blank"
          >Mehr ></a
        >
      </div>
    </div>
    <!--End OverView-->
    <div id="services" class="features container">
      <h2>Leistungen</h2>
      <p class="line"></p>
      <div class="row">
        <p>
          Alle hier aufgeführten Leistungen gelten für Selbstzahler und
          Privatpatienten. Bitte erkundigen sie sich bei Ihrer
          Krankenversicherung, welche Kosten übernommen werden.
        </p>
      </div>
      <div class="card-area">
        <div class="card service bounceIn-element animate__animated">
          <!--Start flip Card-->
          <div class="flip-card">
            <div class="flip-card__container">
              <!--Start flip Card Front Side-->
              <div class="card-front">
                <div
                  class="card-front__text card-front__text--disc service-massagen"
                >
                  <h2 class="card-front__heading">Sieh mich an</h2>
                </div>
                <div class="card-front__bt">
                  <p class="card-front__text-view">Massagen</p>
                </div>
              </div>
              <!--End flip Card Front Side-->
              <!--Start flip Card Back Side-->
              <div class="card-back">
                <img class="image__container" src="project-1.png" alt="image" />
              </div>
              <!--End flip Card Back Side-->
            </div>
          </div>
          <!--Start flip Card Inside Side-->
          <div class="inside-page" id="massagen">
            <div class="inside-page__container">
              <h3 class="inside-page__heading">Massagen</h3>
              <p class="inside-page__text">
                Ich wende verschiedene Massagetechniken, wie klassisch medizinische, Bindegewebsmassagen, Fussrefexmassagen, HotStone Massagen an.
              </p>
              <a href="#" class="inside-page__btn inside-page__btn--text"></a>
            </div>
            <!--End flip Card Inside Side-->
          </div>
        </div>
        <div class="card service bounceIn-element animate__animated">
          <!--Start flip Card-->
          <div class="flip-card">
            <div class="flip-card__container">
              <!--Start flip Card Front Side-->
              <div class="card-front">
                <div
                  class="card-front__text card-front__text--disc service-manuelle-therapie"
                >
                  <h2 class="card-front__heading">Sieh mich an</h2>
                </div>
                <div class="card-front__bt">
                  <p class="card-front__text-view">Komplementäre Physiotherapie</p>
                </div>
              </div>
              <!--End flip Card Front Side-->
              <!--Start flip Card Back Side-->
              <div class="card-back">
                <img class="image__container" src="project-2.png" alt="image" />
              </div>
              <!--End flip Card Back Side-->
            </div>
          </div>
          <!--Start flip Card Inside Side-->
          <div class="inside-page" id="therapie">
            <div class="inside-page__container">
              <h3 class="inside-page__heading">Komplementäre Physiotherapie </h3>
              <p class="inside-page__text">
                Sie verbindet manuell, osteophatische, orthomolikulatische und klassische Physiotherapie miteinander.
              </p>
              <a href="#" class="inside-page__btn inside-page__btn--text"></a>
            </div>
            <!--End flip Card Inside Side-->
          </div>
        </div>
      </div>
      <div class="card-area">
        <div class="card service bounceIn-element animate__animated">
          <!--Start flip Card-->
          <div class="flip-card">
            <div class="flip-card__container">
              <!--Start flip Card Front Side-->
              <div class="card-front">
                <div
                  class="card-front__text card-front__text--disc service-kinesiologisches-taping"
                >
                  <h2 class="card-front__heading">Sieh mich an</h2>
                </div>
                <div class="card-front__bt">
                  <p class="card-front__text-view">Kinesiologisches Taping</p>
                </div>
              </div>
              <!--End flip Card Front Side-->
              <!--Start flip Card Back Side-->
              <div class="card-back">
                <img class="image__container" src="project-3.png" alt="image" />
              </div>
              <!--End flip Card Back Side-->
            </div>
          </div>
          <!--Start flip Card Inside Side-->
          <div class="inside-page" id="kinesiologisches">
            <div class="inside-page__container">
              <h3 class="inside-page__heading">Kinesiologisches Taping</h3>
              <p class="inside-page__text">
                Unterstützt die Muskulatur und Gelenke, lindert Schmerzen,
                fördert die Durchblutung und stimuliert das Lymphsystem.
              </p>
              <a href="#" class="inside-page__btn inside-page__btn--text"></a>
            </div>
            <!--End flip Card Inside Side-->
          </div>
        </div>
        <div class="card service bounceIn-element animate__animated">
          <!--Start flip Card-->
          <div class="flip-card">
            <div class="flip-card__container">
              <!--Start flip Card Front Side-->
              <div class="card-front">
                <div
                  class="card-front__text card-front__text--disc service-manuelle-lymphdrainage"
                >
                  <h2 class="card-front__heading">Sieh mich an</h2>
                </div>
                <div class="card-front__bt">
                  <p class="card-front__text-view">Manuelle Lymphdrainage</p>
                </div>
              </div>
              <!--End flip Card Front Side-->
              <!--Start flip Card Back Side-->
              <div class="card-back">
                <img
                  class="image__container"
                  src="project-12.jpg"
                  alt="image"
                />
              </div>
              <!--End flip Card Back Side-->
            </div>
          </div>
          <!--Start flip Card Inside Side-->
          <div class="inside-page" id="lymphdrainage">
            <div class="inside-page__container">
              <h3 class="inside-page__heading">Manuelle Lymphdrainage</h3>
              <p class="inside-page__text">
                Ist eine sanfte Massagetechnik zur Behandlung von sekundären und
                primären Lymphödeme.
              </p>
              <a href="#" class="inside-page__btn inside-page__btn--text"></a>
            </div>
            <!--End flip Card Inside Side-->
          </div>
        </div>
      </div>
      <div class="card-area">
        <div class="card service bounceIn-element animate__animated">
          <!--Start flip Card-->
          <div class="flip-card">
            <div class="flip-card__container">
              <!--Start flip Card Front Side-->
              <div class="card-front">
                <div
                  class="card-front__text card-front__text--disc service-schröpfen"
                >
                  <h2 class="card-front__heading">Sieh mich an</h2>
                </div>
                <div class="card-front__bt">
                  <p class="card-front__text-view">Schröpfen</p>
                </div>
              </div>
              <!--End flip Card Front Side-->
              <!--Start flip Card Back Side-->
              <div class="card-back">
                <img class="image__container" src="project-8.png" alt="image" />
              </div>
              <!--End flip Card Back Side-->
            </div>
          </div>
          <!--Start flip Card Inside Side-->
          <div class="inside-page" id="schröpfen">
            <div class="inside-page__container">
              <h3 class="inside-page__heading">Schröpfen</h3>
              <p class="inside-page__text">
                Mit dem sogenanntem Feuerschröpfen wird Wärme in den Körper
                eingebracht. Stagnationen werden gelöst, das Qi bewegt.
              </p>
              <a href="#" class="inside-page__btn inside-page__btn--text"></a>
            </div>
            <!--End flip Card Inside Side-->
          </div>
        </div>
        <div class="card service bounceIn-element animate__animated">
          <!--Start flip Card-->
          <div class="flip-card">
            <div class="flip-card__container">
              <!--Start flip Card Front Side-->
              <div class="card-front">
                <div
                    class="card-front__text card-front__text--disc service-ernährung"
                >
                  <h2 class="card-front__heading">Sieh mich an</h2>
                </div>
                <div class="card-front__bt">
                  <p class="card-front__text-view">
                    Ernährungsberatung nach TCM
                  </p>
                </div>
              </div>
              <!--End flip Card Front Side-->
              <!--Start flip Card Back Side-->
              <div class="card-back">
                <img
                    class="image__container"
                    src="project-10.png"
                    alt="image"
                />
              </div>
              <!--End flip Card Back Side-->
            </div>
          </div>
          <!--Start flip Card Inside Side-->
          <div class="inside-page" id="ernährung">
            <div class="inside-page__container">
              <h3 class="inside-page__heading">
                Ernährungsberatung nach TCM
              </h3>
              <p class="inside-page__text">
                Sie möchten ab-/zunehmen, haben Magen-Darmbeschwerden, erhöhten Blutdruck/ Cholesterinwerte, sie fühlen sich müde/ausgebrannt, haben häufig Infekte, leiden unter Allergien/ Unverträglichkeiten? Bitte vereinbaren sie einen Termin zur Ernährungsberatung nach TCM bei mir !
              </p>
              <a href="#" class="inside-page__btn inside-page__btn--text"></a>
            </div>
            <!--End flip Card Inside Side-->
          </div>
        </div>

      </div>
      <div class="card-area">
        <div class="card service bounceIn-element animate__animated">
          <!--Start flip Card-->
          <div class="flip-card">
            <div class="flip-card__container">
              <!--Start flip Card Front Side-->
              <div class="card-front">
                <div
                  class="card-front__text card-front__text--disc service-traditionelle-chinesische-medizin"
                >
                  <h2 class="card-front__heading">Sieh mich an</h2>
                </div>
                <div class="card-front__bt">
                  <p class="card-front__text-view">Akupunktur</p>
                </div>
              </div>
              <!--End flip Card Front Side-->
              <!--Start flip Card Back Side-->
              <div class="card-back">
                <img class="image__container" src="project-6.png" alt="image" />
              </div>
              <!--End flip Card Back Side-->
            </div>
          </div>
          <!--Start flip Card Inside Side-->
          <div class="inside-page" id="akupunktur">
            <div class="inside-page__container">
              <h3 class="inside-page__heading">Akupunktur</h3>
              <p class="inside-page__text">
                Gezielt gesetzte Reize mit Akupunkturnadeln kann Funktionen von
                Organen, Muskeln, Haut und Sehnen beeinflussen. Vielfach
                wissenschaftliche Studien zeigen, körperliche Auswirkungen und
                positive Behandlungsergebnisse.
              </p>
              <ul class="list-group-horizontal-order">
                <li>schmerzlindern</li>
                <li>abschwellend</li>
                <li>den Muskeltonus regulierend</li>
                <li>durchblutungsfördern</li>
                <li>immunstärkend</li>
                <li>vegetativ regulierend</li>
                <li>psychisch harmonisierend</li>
              </ul>
              <a href="#" class="inside-page__btn inside-page__btn--text"></a>
            </div>
            <!--End flip Card Inside Side-->
          </div>
        </div>
        <div class="card service bounceIn-element animate__animated">
          <!--Start flip Card-->
          <div class="flip-card">
            <div class="flip-card__container">
              <!--Start flip Card Front Side-->
              <div class="card-front">
                <div
                    class="card-front__text card-front__text--disc service-arzneimittel"
                >
                  <h2 class="card-front__heading">Sieh mich an</h2>
                </div>
                <div class="card-front__bt">
                  <p class="card-front__text-view">
                    Chinesische Kräutertherapie
                  </p>
                </div>
              </div>
              <!--End flip Card Front Side-->
              <!--Start flip Card Back Side-->
              <div class="card-back">
                <img
                    class="image__container resize-img"
                    src="project-9.png"
                    alt="image"
                />
              </div>
              <!--End flip Card Back Side-->
            </div>
          </div>
          <!--Start flip Card Inside Side-->
          <div class="inside-page" id="arzneimittel">
            <div class="inside-page__container">
              <h3 class="inside-page__heading">Chinesische Kräutertherapie</h3>
              <p class="inside-page__text">
                Blätter, Knollen, Pilze, Samen, Wurzeln, Mineralien oder
                tierische Inhaltsstoffe kennt die TCM. Sie werden als TEE,
                Pulver, Tabletten oder Kräutermixturen verabreicht.
              </p>
              <a href="#" class="inside-page__btn inside-page__btn--text"></a>
            </div>
            <!--End flip Card Inside Side-->
          </div>
        </div>

      </div>
      <div class="card-area">
        <div class="card service bounceIn-element animate__animated">
          <!--Start flip Card-->
          <div class="flip-card">
            <div class="flip-card__container">
              <!--Start flip Card Front Side-->
              <div class="card-front">
                <div
                  class="card-front__text card-front__text--disc service-moxibution"
                >
                  <h2 class="card-front__heading">Sieh mich an</h2>
                </div>
                <div class="card-front__bt">
                  <p class="card-front__text-view">Moxatherapie</p>
                </div>
              </div>
              <!--End flip Card Front Side-->
              <!--Start flip Card Back Side-->
              <div class="card-back">
                <img class="image__container" src="project-7.png" alt="image" />
              </div>
              <!--End flip Card Back Side-->
            </div>
          </div>
          <!--Start flip Card Inside Side-->
          <div class="inside-page" id="moxibution">
            <div class="inside-page__container">
              <h3 class="inside-page__heading">Moxatherapie</h3>
              <p class="inside-page__text">
                Moxakraut wird aus den getrockneten Blättern der Artemisia Vulgaris (Beifuß) gewonnen. Die eindringende Wärme lindert Schmerzen, stimuliert und löst Blockaden.
              </p>
              <a href="#" class="inside-page__btn inside-page__btn--text"></a>
            </div>
            <!--End flip Card Inside Side-->
          </div>
        </div>
        <div class="card service bounceIn-element animate__animated">
          <!--Start flip Card-->
          <div class="flip-card">
            <div class="flip-card__container">
              <!--Start flip Card Front Side-->
              <div class="card-front">
                <div
                    class="card-front__text card-front__text--disc service-tiuana"
                >
                  <h2 class="card-front__heading">Sieh mich an</h2>
                </div>
                <div class="card-front__bt">
                  <p class="card-front__text-view">
                    TUINA - Chinesische Heilmassage
                  </p>
                </div>
              </div>
              <!--End flip Card Front Side-->
              <!--Start flip Card Back Side-->
              <div class="card-back">
                <img
                    class="image__container"
                    src="project-11.png"
                    alt="image"
                />
              </div>
              <!--End flip Card Back Side-->
            </div>
          </div>
          <!--Start flip Card Inside Side-->
          <div class="inside-page" id=" tuina">
            <div class="inside-page__container">
              <h3 class="inside-page__heading">
                TUINA - Chinesische Heilmassage
              </h3>
              <p class="inside-page__text">
                Mit speziellen Griftechniken und Akupressur lindert die TUINA,
                als manuelle Therapie der TCM, Schmerzen, Bluthochdruck,
                Gastritis, Verstopfungen, Asthma, Schlafstörungen, Migräne, HNO-
                Erkrankungen, Kiefergelenkbeschwerden,
                gynäkologische Beschwerden. Als Prävention reguliert sie das vegetative
                Nervensystem und stärkt somit das Qi und Ihr Immunsystem.
              </p>
              <a href="#" class="inside-page__btn inside-page__btn--text"></a>
            </div>
            <!--End flip Card Inside Side-->
          </div>
        </div>

      </div>
      <div class="card-area">
        <div class="card service bounceIn-element animate__animated">
          <!--Start flip Card-->
          <div class="flip-card">
            <div class="flip-card__container">
              <!--Start flip Card Front Side-->
              <div class="card-front">
                <div
                  class="card-front__text card-front__text--disc service-nordic-walking"
                >
                  <h2 class="card-front__heading">Sieh mich an</h2>
                </div>
                <div class="card-front__bt">
                  <p class="card-front__text-view">Nordic Walking</p>
                </div>
              </div>
              <!--End flip Card Front Side-->
              <!--Start flip Card Back Side-->
              <div class="card-back">
                <img class="image__container" src="project-5.png" alt="image" />
              </div>
              <!--End flip Card Back Side-->
            </div>
          </div>
          <!--Start flip Card Inside Side-->
          <div class="inside-page" id="nordic">
            <div class="inside-page__container">
              <h3 class="inside-page__heading">Nordic Walking</h3>
              <p class="inside-page__text">
                Starten sie bewegt in den Tag und erlernen sie die Technik des
                Nordic Walkings.
              </p>
              <a href="#" class="inside-page__btn inside-page__btn--text"></a>
            </div>
            <!--End flip Card Inside Side-->
          </div>
        </div>
        <div class="card service bounceIn-element animate__animated">
          <!--Start flip Card-->
          <div class="flip-card">
            <div class="flip-card__container">
              <!--Start flip Card Front Side-->
              <div class="card-front" id="qigong">
                <div
                    class="card-front__text card-front__text--disc service-qigong"
                >
                  <h2 class="card-front__heading">Sieh mich an</h2>
                </div>
                <div class="card-front__bt">
                  <p class="card-front__text-view">Qigong</p>
                </div>
              </div>
              <!--End flip Card Front Side-->
              <!--Start flip Card Back Side-->
              <div class="card-back">
                <img
                    class="image__container resize-img-top"
                    src="project-13.png"
                    alt="image"
                />
              </div>
              <!--End flip Card Back Side-->
            </div>
          </div>
          <!--Start flip Card Inside Side-->
          <div class="inside-page">
            <div class="inside-page__container">
              <h3 class="inside-page__heading">
                Qigong
              </h3>
              <p class="inside-page__text">
                Lernen und üben Sie mit mir das Stille und Bewegte QiGong -yangsheng-.
              </p>
              <a href="#" class="inside-page__btn inside-page__btn--text"></a>
            </div>
            <!--End flip Card Inside Side-->
          </div>
        </div>

      </div>
      <div class="card-area">
        <div class="card service bounceIn-element animate__animated">
          <!--Start flip Card-->
          <div class="flip-card">
            <div class="flip-card__container">
              <!--Start flip Card Front Side-->
              <div class="card-front">
                <div
                  class="card-front__text card-front__text--disc service-gutscheine"
                >
                  <h2 class="card-front__heading">Sieh mich an</h2>
                </div>
                <div class="card-front__bt">
                  <p class="card-front__text-view">Geschenk-Gutscheine</p>
                </div>
              </div>
              <!--End flip Card Front Side-->
              <!--Start flip Card Back Side-->
              <div class="card-back">
                <img
                  class="image__container"
                  src="project-17.jpeg"
                  alt="image"
                />
              </div>
              <!--End flip Card Back Side-->
            </div>
          </div>
          <!--Start flip Card Inside Side-->
          <div class="inside-page" id="gutscheine">
            <div class="inside-page__container">
              <h3 class="inside-page__heading">Geschenk-Gutscheine</h3>
              <p class="inside-page__text">
                Überraschen Sie Ihre Lieben zu Ostern, Weihnachten, Geburtstag oder um
                Danke zu sagen. Mit einem Gutschein für eine klassische
                Massage, HotStone-Massage, einer Tuina Prävention oder zur
                Physiotherapie.
              </p>
              <a href="#" class="inside-page__btn inside-page__btn--text"></a>
            </div>
            <!--End flip Card Inside Side-->
          </div>
        </div>
      </div>
    </div>
    <!--End Features-->
    <!--Start About me -->
    <div class="about-me-container" id="about-me">
      <div class="about-me-box">
        <div class="about-img-container">
          <div class="img-box">
            <img src="uber_mich.jpg" />
          </div>
        </div>
        <div class="about-discretion fadeInRight-element animate__animated">
          <h2>Sabine Hahm</h2>
          <p class="line"></p>
          <p class="about-me-text">
            Geboren wurde ich 1971 in Kleinmachnow. Aufgewachsen und zur Schule
            gegangen bin ich in Teltow. Mit meiner Familie zog ich nach Stahnsdorf/Kienwerder. Meine Ausbildung zur Physiotherapeutin
            absolvierte ich, nach einer fünfjährigen Erziehungspause, an der
            Schule für Physiotherapie in Potsdam. Vorerst arbeitete ich als
            mobile Physiotherapeutin in Seniorenheimen unserer Region. Seit über
            12 Jahren bin ich im Bereich Rehabilitation für psycho/somatische
            Erkrankungen, Kardiologie und post/longCovid tätig. Meine Ausbildung
            zur Heilpraktikerin konnte ich 2017 mit der amtsärztlichen
            Überprüfung in Potsdam erfolgreich beenden. Weiterbildungen spielten
            und spielen in meinem Leben eine zentrale Rolle. Vorerst absolvierte
            ich Fortbildungen in Manueller Lymphdrainage, Nordic Walking,
            komplementären physiotherapeutischen Therapien an der medizinischen
            Hochschule in Hannover. Später entdeckte ich die Leidenschaft an der
            traditionellen chinesischen Medizin. Es folgten Ausbildungen in der
            TUINA, Akupunktur, Narbenentstörung, Schröpfen an der Deutschen
            TUINA Akademie in Leipzig, und dem Qigong am ShenMen-Institut.
            Ernährung und Kräutertherapien sind ein weiterer Schwerpunkt. Seit
            11/2022 betreibe ich nebenberuflich meine eigene Praxis für
            komplementäre physiotherapeutische Therapie und traditionelle
            chinesische Medizin in Stahnsdorf/ Kienwerder, Alte Feldmark 11a.
          </p>
          <a
            class="about-praxis bounceIn-element animate__animated"
            href="mailto: sabine.hahm@kp-tcm-hahm.de"
            target="_blank"
            >Kontakt</a
          >
        </div>
      </div>
    </div>
    <div id="galarie" class="projects-sec">
      <h2>Galerie</h2>
      <p class="line"></p>
      <div class="row">
        <img
          class="col-lg-4 resize-img resize-img-top"
          src="project-13.png"
          alt=""
        />
        <img class="col-lg-4 resize-img" src="project-2.png" alt="" />
        <img class="col-lg-4 resize-img" src="project-14.png" alt="" />
      </div>
      <div class="row">
        <img class="col-lg-6 resize-img-center " src="project-15.png" alt="" />
        <img class="col-lg-6 resize-img-center " src="project-5.png" alt="" />
      </div>
      <div class="row">
        <img class="col-lg-4 resize-img-bottom " src="project-9.png" alt="" />
        <img class="col-lg-4 resize-img " src="project-10.png" alt="" />
        <img class="col-lg-4 resize-img " src="project-11.png" alt="" />
      </div>
      <div class="row">
        <img class="col-lg-6 resize-img " src="project-7.png" alt="" />
        <img class="col-lg-6 resize-img " src="project-8.png" alt="" />
      </div>
      <div class="row">
        <img class="col-lg-4 resize-img-center " src="project-16.jpg" alt="" />
        <img class="col-lg-4 resize-img " src="project-4.jpg" alt="" />
        <img class="col-lg-4 resize-img" src="project-12.jpg" alt="" />
      </div>
    </div>
    <div class="open-time-map" id="open-time-map">
      <div class="map-container">
        <h2>Standort</h2>
        <p class="line"></p>
        <div class="location-window  bounceIn-element animate__animated">
          <iframe
            class="map"
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2435.6673359080555!2d13.182737799999998!3d52.37645179999999!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x47a85ebf7ac8a765%3A0x560bb7549b4cd811!2sAlte%20Feldmark%2011A%2C%2014532%20Stahnsdorf!5e0!3m2!1sen!2sde!4v1661696115280!5m2!1sen!2sde"
            style="border: 0"
            allowfullscreen=""
            loading="lazy"
          ></iframe>
        </div>
      </div>
      <div class="open-time-container">
        <h2>Öffnungszeiten</h2>
        <p class="line"></p>
        <div class="list-open-time ">
          <div class="row-day fw-bold">Montag:</div>
          <div class="row-time fw-bold fadeInRight-element animate__animated">Nach Vereinbarung</div>
          <div class="row-day fw-bold">Dienstag:</div>
          <div class="row-time fw-bold fadeInRight-element animate__animated">geschlossen</div>
          <div class="row-day fw-bold">Mittwoch:</div>
          <div class="row-time fw-bold fadeInRight-element animate__animated">Nach Vereinbarung</div>
          <div class="row-day fw-bold">Donnerstag:</div>
          <div class="row-time fw-bold fadeInRight-element animate__animated">geschlossen</div>
          <div class="row-day fw-bold">Freitag:</div>
          <div class="row-time fw-bold fadeInRight-element animate__animated">geschlossen</div>
          <div class="row-day fw-bold">Samstag:</div>
          <div class="row-time fw-bold fadeInRight-element animate__animated">geschlossen</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
export default {
  name: "HomeView",
  components: {},
};
</script>

<style lang="scss">
$blueCol: #646060;
$greenCol: #99b781;
$LightgreenCol: #99b781;
$pinkCol: #ffadad;
$whiteCol: #fdf8fa;
$SkyCol: #99b781;
$fontSize: 4rem;
$fontSizeTitle: 2rem;
.home {
  //max-width: 99.1%;
  max-width: 100%;
  text-align: center;

  h2 {
    margin-bottom: 3rem;
    font-family: "News Cycle", sans-serif;
    font-weight: bolder;
    @media (max-width: 850px) {
      margin-bottom: 1.5rem;
    }
  }

  .line {
    background: $LightgreenCol;
    width: 4.5rem;
    height: 0.19rem;
    margin-top: -2.5%;
    border-radius: 22%;
    border-top-right-radius: 100%;
    margin-left: 47.5%;
    margin-bottom: 3rem;
  }

  .features {
    margin-top: 100px;
    max-width: 100%;
    max-height: fit-content;
  }
}

.container .row {
  justify-content: space-evenly;
}

.service {
  background: $greenCol;
  margin-bottom: 50px;
  padding: 15px;
  cursor: pointer;
  border-radius: 3%;

  i {
    background: $LightgreenCol;
    color: $whiteCol;

    padding: 5px 15px 5px 5px;
    font-size: 30px;
    margin-top: 70px;
  }

  h3,
  p {
    color: $whiteCol;
  }

  h3 {
    margin-top: 15px;
  }
}
.note-info{
  text-align: center;
  letter-spacing: 1.2px;
  font-size: 2rem;
  font-family: "News Cycle", sans-serif;
  @media (max-width: 500px) {
    font-size: 1.4rem;
    p{
      font-weight: 300;
      span{
        font-weight: bolder;
      }
    }
  }
  .notes {
    margin-bottom: 1rem;
    padding: 4px 12px;
    p{
      margin-top: 0.5rem;
      span{
        font-weight: bold;
      }
    }
    a {
      color: #2c3e50 !important;
    }
  }
  .info {
    background: #99b781;
    border-left: 6px solid $greenCol;
    border-right: 6px solid $greenCol;
    strong{
      font-family: "Bebas Neue", cursive;
      font-size: 2.3rem;
      @media (max-width: 500px) {
        font-size: 1.5rem;
      }
    }
  }
}
.overview {
  height: fit-content;
  margin-top: 5rem !important;
  .about-praxis {
    transition: ease-in-out 0.4s;
    background: #99b781;
    &:hover {
      background: $blueCol;
      color: $whiteCol;
    }
  }
}
.overview-text {
  width: 60% !important;
  text-align: justify;
  word-break: keep-all;
  background: rgba(196, 223, 170, 0.27);
  padding: 2rem !important;
  padding-top: 2rem;
  padding-top: 5rem !important;
  border-top-left-radius: 30%;
  border-top-right-radius: 30%;
  text-align: center;
  letter-spacing: 1px;
  @media (max-width: 850px) {
    width: 88% !important;
    .overview-text-content {
      text-align: justify;
      letter-spacing: 0.4px;
    }
  }
  .cv-link {
    cursor: pointer;
    text-decoration: none;
    width: 100%;
    color: $whiteCol;
    padding: 0.5rem 1rem 0.5rem 1rem;
    background: $blueCol;
  }
}
.featured-work {
  .list {
    list-style-type: none;
    padding: 100px 0px;
    margin-left: 0.1rem;
    margin-right: 0.1rem;

    li {
      padding: 10px;
      border: 1px solid $blueCol;
      margin-left: 10px;
      cursor: pointer;
      -webkit-transition: all 0.3s ease-in-out;

      -moz-transition: all 0.3s ease-in-out;

      -o-transition: all 0.3s ease-in-out;
    }

    li.active {
      background: $greenCol;
      color: $whiteCol;
    }

    li:hover {
      color: $whiteCol;
      background: $greenCol;
    }
  }
}

//Start Projects Section
.service-title {
  font-size: $fontSize;
}

.projects-sec {
  contain: content;
  img {
    margin-bottom: 2rem;
    padding-right: 0;
    cursor: pointer;
    height: 26rem;
    object-fit: cover;
  }
}

@media (max-width: 900px) {
  .home .line {
    margin-left: 45.5%;
  }
  .featured-work {
    .list {
      margin-left: 0.05rem;
      margin-right: 0rem;

      li {
        margin-left: 0;
      }
    }
  }
}

@media (max-width: 800px) {
  .home .line {
    margin-left: 41.5%;
  }
  .projects-sec {
    img {
      width: 100%;
    }
  }
}

.card-front__heading {
  font-size: 16px;
  margin-top: 8px;
}

//Star heading for inside Page
.inside-page__heading {
  padding-bottom: 1rem;
  width: 100%;
}

//End heading for inside Page
//Start Heading with Text front below
.inside-page__heading,
.card-front__text-view {
  font-size: 1.15rem;
  font-weight: 784;
  margin: 0rem;
  color: $blueCol;
}

//End Heading with Text front conver
.card-front__text {
  color: $whiteCol;
  font-family: var(--font-family--text-containe);
  letter-spacing: 0.7px;
}

//Start pricing text on card front cover
.card-front__text-price {
  font-size: 1.2rem;
  margin-top: -0.2rem;
}

//End pricing text on card front cover
//Start For inside bake text
.inside-page__text {
  color: $LightgreenCol;
  height: fit-content;
  overflow-x: auto;
  padding-right: 0.4rem;
  padding-left: 0.4rem;
}

//End For inside bake text
.inside-page__btn {
  background-color: transparent;
  border: 3px solid var(--_c-dark-primary);
  border-radius: 10%;
  font-size: 1.2rem;
  font-weight: 600;
  margin-top: 0;
  overflow: hidden;
  padding: 0.7rem 0.75rem;
  position: relative;
  text-decoration: none;
  transition: 0.3s ease-in-out;
  width: 80%;
  z-index: 10;
  font-family: var(--font-family--text-containe);
}
.inside-page__btn::before {
  content: "";
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  transform: scaleY(0);
  transition: 0.3s ease-in-out;
  width: 100%;
  z-index: -1;
}
.inside-page__btn--text {
  border-color: var(--_c-dark-primary);
  color: var(--_c-dark-primary);

  &::before {
    background-color: var(--_c-paige-primary);
  }
}

.inside-page__btn:hover {
  color: var(--c-white);

  &:hover::before {
    transform: scaleY(1);
  }
}

//Sarte Conatiner
.card-area {
  align-items: center;
  display: flex;
  flex-wrap: nowrap;
  height: 100%;
  justify-content: space-evenly;
  padding: 1rem;
  margin-top: 2rem;
  @media (max-width: 900px) {
    flex-direction: column;
  }
}

//End Container
.card {
  padding: 0;
  height: 23rem !important;
  position: relative;
  transition: all 1s ease;
  width: 30%;
  background: #f6f6f6 !important;
  border: none !important;
  @media (max-width: 900px) {
    width: 95%;
    z-index: 5;
  }
}

//Start outer container of flip card
.flip-card {
  height: 23rem;
  perspective: 100rem;
  position: absolute;
  right: 0;
  transition: 1s ease-in-out;
  visibility: hidden;
  width: 100%;
  z-index: 20;
}

//End outer container of flip card
//Start to cange the situation of visibilty of the flip card to visbile from hidden
.flip-card > * {
  visibility: visible;
}

//End to cange the situation of visibilty of the flip card to visbile from hidden
.flip-card__container {
  height: 100%;
  position: absolute;
  right: 0;
  transform-origin: left;
  transform-style: preserve-3d; //make transform to 3d form origin left
  transition: 1s ease-in-out;
  width: 100%;
  @media (max-width: 900px) {
    transform-origin: bottom; //flip bottom
  }
}

//Start to hide the conatin of front or back card
.card-back {
  backface-visibility: hidden;
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  //width: 100%;
}
.card-front {
  backface-visibility: hidden;
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  //width: 100%;
}
.resize-img {
  aspect-ratio: 3/1;
  object-position: bottom;
}
//Start style front card
.card-front {
  background-color: white;
  height: 23rem;
  width: 100%;
  transition: 1s ease-in-out;
  h2 {
    height: 100%;
    width: 100%;
    color: $LightgreenCol;
    //change background: rgba(0, 0, 0, 0.38);
    margin: 0 !important;
    align-items: center;
    display: flex;
    justify-content: center;
    font-weight: bolder;
  }
}

//End style front card
.card-front__text {
  align-items: center;
  //clip-path: polygon(0 0, 100% 0, 100% 90%, 57% 90%, 50% 100%, 43% 90%, 0 90%); //shape ^
  display: flex;
  flex-direction: column;
  height: 19rem;
  justify-content: center;
  padding: 15px;
}

.card-front__text--disc {
  background: linear-gradient(to bottom, green, red);
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  padding: 0 !important;
}
.service-massagen {
  background-image: url("../../public/project-1.png");
}
.service-manuelle-therapie {
  background-image: url("../../public/project-2.png");
}
.service-kinesiologisches-taping {
  background-image: url("../../public/project-3.png");
}
.service-pmr-nach-jakobsonn {
  background-image: url("../../public/project-4.jpg");
  background-position-y: bottom;
}
.service-nordic-walking {
  background-image: url("../../public/project-5.png");
}
.service-traditionelle-chinesische-medizin {
  background-image: url("../../public/project-6.png");
  background-position: initial !important;
}
.service-moxibution {
  background-image: url("../../public/project-7.png");
}
.service-schröpfen {
  background-image: url("../../public/project-8.png");
}
.service-arzneimittel {
  background-image: url("../../public/project-9.png");
}
.service-ernährung {
  background-image: url("../../public/project-10.png");
}
.service-tiuana {
  background-image: url("../../public/project-11.png");
}
.service-manuelle-lymphdrainage {
  background-image: url("../../public/project-12.jpg");
}
.service-qigong {
  background-image: url("../../public/project-13.png");
  background-position-y: 21%;
}
.service-gutscheine {
  background-image: url("../../public/project-17.jpeg");
}
.card-front__bt {
  align-items: center;
  display: flex;
  justify-content: center;
  height: 17%;
  p {
    color: $greenCol;
  }
}

//Start Image ocntainer
.image__container {
  height: auto;
  height: 100%;
  object-fit: cover;
  width: 100%;
}
.resize-img-bottom {
  object-position: bottom;
}

//End Image ocntainer
//Start Inside Page
.inside-page {
  background-color: white;
  height: 100%;
  padding: 0rem;
  position: absolute;
  right: 0;
  transition: 1s ease-in-out;
  width: 100%;
  z-index: 1;
  @media (max-width: 900px) {
    height: 23rem !important;
  }
}

//End Inside Page
.inside-page__container {
  align-items: center;
  display: flex;
  flex-direction: column;
  height: 100%;
  text-align: center;
  justify-content: center;
  @media (max-width: 900px) {
    height: 23rem;
    font-size: 1.5rem;
  }
}
.inside-page__container .inside-page__text {
  color: $blueCol;
  @media (max-width: 900px) {
    font-size: 13px;
  }
}
.inside-page__container h3 {
  color: $blueCol;
}
.card-back {
  background-color: white;
  width: 100%;
  @media (min-width: 900px) {
    transform: rotateY(180deg);
  }
  @media (max-width: 900px) {
    transform: rotateX(180deg);
  }
}
.card-front__text h2 {
  transition: 1s ease-in-out;
}
.card-front__bt p {
  transition: 1s ease-in-out;
}
//Start Card Function
.card:hover {
  width: 60%; //to move the next card a side
  height: 15rem;
  box-shadow: 2px 4px 4px 6px;
  box-shadow: 3px 3px #6d8b74, -1em 0 0.4em $LightgreenCol;
  @media (max-width: 900px) {
    width: 95%;
    transition: 0.4s ease-in;
    margin-bottom: 24rem;
    z-index: 19;
  }
  .card-front__text h2 {
    color: white;
    transition: 1s ease-in-out;
  }
  .card-front__bt p {
    transition: 1s ease-in-out;
    color: white;
  }
  .flip-card {
    width: 50%;
    @media (max-width: 900px) {
      width: 100%;
    }
  }
  .inside-page {
    width: 50%;
    @media (max-width: 900px) {
      width: 100%;
      height: 50%;
    }
  }
  .card-front {
    @media (min-width: 900px) {
      width: 0%;
    }
  }
}

.card:hover .flip-card__container {
  @media (min-width: 900px) {
    transform: rotateY(-180deg); //for desk top to flip left
  }
  @media (max-width: 900px) {
    transform: rotateX(-180deg); //for mobile top to flip bottom
  }
}
.resize-img-top {
  aspect-ratio: 3/1;
  object-fit: cover;
  object-position: top;
}
.resize-img-center {
  aspect-ratio: 3/1;
  object-fit: cover;
  object-position: center;
}
.list-group-horizontal-order {
  width: 99%;
  contain: content;
  font-size: 12px;
  padding-left: 1rem !important;
  li {
    float: left;
    margin-left: 1rem;
  }
}
//End Projects Section
//Start About me Section
.about-me-container {
  border-bottom: 2px solid #99b781;
  margin-bottom: 12rem;
  //background: #dfdfdf;
  background: #ffffff;
  margin-top: 12rem;
  h2 {
    font-family: "Bebas Neue", cursive;
    font-size: 4rem;
    color: #99b781;
    margin-bottom: 2rem;
  }
  .line {
    background: #99b781;
    width: 10rem;
    margin-left: 42.5%;
    @media (max-width: 1000px) {
      margin-left: 38.5%;
    }
    @media (max-width: 600px) {
      margin-left: 31.5%;
    }
  }
  .about-me-box {
    display: flex;
    flex-direction: row;
    position: relative;
    @media (max-width: 1400px) {
      flex-direction: column;
    }
    .about-img-container {
      width: 35%;
      margin-bottom: 0;
      background: #f6f6f6;
      margin: 0 !important;
      @media (max-width: 1400px) {
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: flex-end;
        .img-box {
          width: 100%;
          margin-bottom: 0 !important;
          padding: 0;
          @media (min-width: 600px) and (max-width: 1400px) {
            padding: 0% 20%;
          }
        }

      }
      img {
        object-fit: cover;
        object-position: top;
        @media (max-width: 1400px) {
          border-radius: 100%;
          margin-bottom: -11rem;
          object-fit: cover;
          width: 85%;
          height: 20rem;
          object-position: 0% 0%;
        }
        @media (max-width: 400px) {
          object-position: 0% 0%;
        }
      }
    }
    .about-discretion {
      padding-right: 1.5rem;
      padding-left: 1.5rem;
      width: 65%;
      right: 0;
      padding-top: 3rem;
      position: absolute;
      @media (max-width: 1400px) {
        position: initial;
        width: 100%;
        margin-top: 11rem;
        margin-bottom: 3rem;
      }
      .about-me-text {
        font-weight: bold;
        width: 100%;
        text-align: justify;
        letter-spacing: 1.3px;
        @media (max-width: 1400px) {
          letter-spacing: 0;
        }
      }
    }
  }
  .about-praxis {
    background: #99b781;
    color: $whiteCol;
    &:hover {
      background: $blueCol;
    }
  }
}
//End About me Section
//open time and map
.open-time-map {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  justify-content: space-around;
  margin-bottom: 7rem;
  margin-top: 5rem;
  @media (max-width: 900px) {
    flex-direction: column;
  }
  h2 {
    margin-bottom: 2rem;
  }
  .map-container {
    @media (max-width: 900px) {
      width: 80%;
      order: 2;
    }
    .line {
      margin-left: 38.5%;
    }
    .location-window {
      width: 100%;
      height: 14rem;
      border: 3px solid $blueCol;
      @media (max-width: 900px) {
        height: 20rem;
      }
      .map {
        width: 100%;
        height: 100%;
        border-radius: 8%;
      }
    }
  }
  .open-time-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    @media (max-width: 900px) {
      margin-bottom: 5rem;
    }
    .line {
      margin-left: 3.5%;
    }
    .list-open-time {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      grid-template-rows: auto 1fr;
      grid-auto-flow: row;
      max-width: 500px;
      .row-day {
        text-align: left;
        color: $blueCol !important;
      }
      .row-time {
        color: $greenCol !important;
        @media (max-width: 900px) {
          font-size: 14px;
        }
      }
    }
  }
}
.about-praxis {
  height: 2.5rem;
  width: 8rem;
  display: inline-block;
  text-align: center;
  padding-top: 0.6rem;
  color: $whiteCol;
  font-weight: bold;
  cursor: pointer;
  transition: ease-in-out 0.4s;
  text-decoration: none;
  border-radius: 10%;
}

</style>
