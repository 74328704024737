var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{attrs:{"id":"app"}},[_c('nav',{staticClass:"navbar navbar-expand-lg",attrs:{"id":"navbar"}},[_c('div',{staticClass:"container nav-container"},[_c('div',{staticClass:"container-fluid row"},[_vm._m(0),_c('div',{staticClass:"navbar-collapse collapse col-lg-7",attrs:{"id":"nav-btn"}},[_c('ul',{staticClass:"navbar-nav ms-auto mb-2 mb-lg-0"},[_vm._m(1),_c('li',{staticClass:"nav-item"},[_c('div',{staticClass:"dropdown"},[_c('a',{staticClass:"btn dropdown-toggle",attrs:{"href":"#","role":"button","id":"dropdownMenuButton","data-toggle":"dropdown"},on:{"click":function($event){return _vm.dropdownButton()}}},[_vm._v(" Leistungen ")]),_vm._m(2)])]),_c('li',{staticClass:"nav-item",attrs:{"hidden":""}},[_c('div',{staticClass:"dropdown"},[_c('a',{staticClass:"btn dropdown-toggle",attrs:{"href":"#","role":"button"},on:{"click":function($event){return _vm.dropdownKurseButton()}}},[_vm._v(" Kurse ")]),_vm._m(3)])]),_vm._m(4),_vm._m(5),_vm._m(6)])])])])]),_c('div',{staticClass:"slider",attrs:{"id":"slider"}},[_c('div',{staticClass:"overlay"}),_c('div',{staticClass:"img-container",attrs:{"tag":"div"}},_vm._l(([_vm.currentIndex]),function(i){return _c('div',{key:i},[_c('img',{staticClass:"slider-img",attrs:{"id":"slider-img","src":_vm.currentImg}}),_c('h1',{staticClass:"title-text"},[_vm._v(_vm._s(_vm.currentTitle))])])}),0),_c('a',{staticClass:"prev",attrs:{"href":"#"},on:{"click":_vm.prev}},[_vm._v("❮ ")]),_c('a',{staticClass:"next",attrs:{"href":"#"},on:{"click":_vm.next}},[_vm._v("❯ ")])]),_c('router-view'),_vm._m(7)],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"logo-container col-md-12 col-lg-5 col-sm-12"},[_c('div',{staticClass:"row col-lg-6 col-md-12 col-sm-12"},[_c('div',{staticClass:"logo-name row col-lg-12 col-md-10 col-sm-10"},[_c('a',{staticClass:"navbar-brand col-lg-12 col-sm-12 col-md-12",attrs:{"href":"#"}},[_c('span',{staticClass:"brand-name-1"},[_vm._v("SABINE HAHM")]),_c('br'),_c('span',{staticClass:"brand-name-2"},[_vm._v("Physiotherapeutin und Heilpraktikerin")])])]),_c('button',{staticClass:"navbar-toggler btn-outline-success col-md-2 col-sm-2",attrs:{"data-bs-toggle":"collapse","data-bs-target":"#nav-btn","aria-controls":"#nav-btn","aria-expanded":"false","aria-label":"Toggle navigation"}},[_c('span',{staticClass:"navbar-toggler-icon"})])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('li',{staticClass:"nav-item"},[_c('a',{staticClass:"nav-link",attrs:{"href":"#about-me"}},[_vm._v("Über mich")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"dropdown-menu",attrs:{"id":"dropdownMenuLink"}},[_c('a',{staticClass:"dropdown-item",attrs:{"href":"#massagen"}},[_vm._v("Massagen")]),_c('a',{staticClass:"dropdown-item",attrs:{"href":"#therapie"}},[_vm._v("Komplementäre Physiotherapie")]),_c('a',{staticClass:"dropdown-item",attrs:{"href":"#kinesiologisches"}},[_vm._v(" Kinesiologisches Taping ")]),_c('a',{staticClass:"dropdown-item",attrs:{"href":"#lymphdrainage"}},[_vm._v("Manuelle Lymphdrainage")]),_c('a',{staticClass:"dropdown-item",attrs:{"href":"#schröpfen"}},[_vm._v("Schröpfen")]),_c('a',{staticClass:"dropdown-item",attrs:{"href":"#ernährung"}},[_vm._v("Ernährungsberatung nach TCM")]),_c('a',{staticClass:"dropdown-item",attrs:{"href":"#akupunktur"}},[_vm._v("Akupunktur")]),_c('a',{staticClass:"dropdown-item",attrs:{"href":"#moxibution"}},[_vm._v("Moxatherapie")]),_c('a',{staticClass:"dropdown-item",attrs:{"href":"#arzneimittel"}},[_vm._v("Chinesische Kräutertherapie")]),_c('a',{staticClass:"dropdown-item",attrs:{"href":"#chinesische"}},[_vm._v(" TUINA - Chinesische Heilmassage ")]),_c('a',{staticClass:"dropdown-item",attrs:{"href":"#gutscheine"}},[_vm._v(" Geschenk-Gutscheine ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"dropdown-menu",attrs:{"id":"dropdownKurseLink"}},[_c('a',{staticClass:"dropdown-item",attrs:{"href":"https://www.docdroid.net/sACDlOy/pmr-kurs-docx","target":"_blank"}},[_vm._v("PMR")]),_c('a',{staticClass:"dropdown-item",attrs:{"href":"https://www.docdroid.net/dYHX8pO/nordicwalking-kurs-docx","target":"_blank"}},[_vm._v("Nordic Walking")]),_c('a',{staticClass:"dropdown-item",attrs:{"href":"https://www.docdroid.net/9tzskI6/qigong-kurse-docx","target":"_blank"}},[_vm._v("Qigong")]),_c('a',{staticClass:"dropdown-item",attrs:{"href":"https://www.docdroid.net/6OwEQPE/vortrage-ernahrung-und-tcm-docx","target":"_blank"}},[_vm._v("Vorträge")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('li',{staticClass:"nav-item"},[_c('a',{staticClass:"nav-link",attrs:{"href":"#galarie"}},[_vm._v("Galerie ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('li',{staticClass:"nav-item"},[_c('a',{staticClass:"nav-link",attrs:{"href":"#footer"}},[_vm._v("kontakt")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('li',{staticClass:"nav-item"},[_c('a',{staticClass:"nav-link",attrs:{"href":"#open-time-map"}},[_vm._v("Öffnungszeiten")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"footer",attrs:{"id":"footer"}},[_c('div',{staticClass:"container"},[_c('div',{staticClass:"row footer-row"},[_c('div',{staticClass:"footer-company-logo col-md-6 col-lg-6 col-sm-12"},[_c('div',{staticClass:"company-info text-start"},[_c('h2',[_c('a',{staticClass:"navbar-brand",attrs:{"href":"#"}},[_c('span',{staticClass:"brand-name-1"},[_vm._v("Sabine Hahm")]),_c('br'),_c('span',{staticClass:"brand-name-2"},[_vm._v("Physiotherapeutin und Heilpraktikerin")])])]),_c('p',[_vm._v("Das Herz im Himmel, den Himmel im Herzen.")])])]),_c('div',{staticClass:"footer-contact col-md-6 col-lg-6 col-sm-12"},[_c('div',{staticClass:"contact-container"},[_c('h2',[_vm._v("Kontakt")]),_c('p',{staticClass:"line"}),_c('ul',{staticClass:"list"},[_c('li',{staticClass:"fw-bold"},[_vm._v(" Email: "),_c('span',{staticClass:"fw-normal"},[_c('a',{attrs:{"href":"mailto: infos@kp-tcm-hahm.de","target":"_blank"}},[_vm._v("infos@kp-tcm-hahm.de")])])]),_c('li',{staticClass:"fw-bold"},[_vm._v(" Adresse: "),_c('span',{staticClass:"fw-normal"},[_vm._v(" Alte Feldmark 11a, 14532 Stahnsdorf")])]),_c('li',{staticClass:"fw-bold"},[_vm._v(" Telefonnummer: "),_c('span',{staticClass:"fw-normal"},[_c('a',{attrs:{"href":"tel:+4915773584878"}},[_vm._v("+49 (0) 15 773 584 878")])])])])])]),_c('div',{staticClass:"col-sm-12 col-md-12 col-lg-12 rights"},[_c('a',{staticClass:"disclaimer",attrs:{"href":"https://www.disclaimer.de/disclaimer.htm?farbe=FFFFFF/000000/000000/000000","target":"_blank"}},[_vm._v("Disclaimer ")]),_c('p',{staticClass:"text-white"},[_vm._v("©2023 SABINE HAHM All Rights Reserved")])])])])])
}]

export { render, staticRenderFns }